/* eslint-disable @typescript-eslint/member-ordering */

import { SettingsParser } from "../settings.parser";

export abstract class URLMap {
	public static readonly parseSettingsURL: () => void = () => {
		const params = SettingsParser.getSettings();
		if (!params || !params.url) this.main_url = window.location.origin + "/irisws";
		else {
			this.main_url = params.url;
			if (!this.main_url!.match(/http:\/\/|https:\/\//)) this.main_url = "http://" + this.main_url;
			if (!this.main_url!.match(/\/irisws/)) this.main_url += "/irisws";
		}
		if (!params || !params.backup_url) this.backup_url = "";
		else {
			if (params.backup_url === "window.location") this.backup_url = window.location.origin + "/irisws";
			else this.backup_url = params.backup_url;
			if (!this.backup_url!.match(/http:\/\/|https:\/\//)) this.backup_url = "http://" + this.backup_url;
			if (!this.backup_url!.match(/\/irisws/)) this.backup_url += "/irisws";
		}
	};

	public static readonly parseSettingsIMSURL: () => void = () => {
		const params = SettingsParser.getSettings();
		if (params.ims) {
			if (params.ims.host && params.ims.port) {
				this.ims_url.host = params.ims.host;
				this.ims_url.port = params.ims.port;
			} else console.error("Error parsing IRIS settings. Missing host and port in IMS");
		}
	};

	public static main_url: string | undefined;
	public static backup_url: string | undefined;
	public static use_backup = false;

	public static ims_url: Uri = {
		host: "video.dev.irisunblur.com",
		port: 9080
	};

	public static WSURL: () => string = () => {
		this.parseSettingsURL();
		return this.use_backup ? this.backup_url! : this.main_url!;
	};

	public static IMSURL: () => Uri = () => {
		this.parseSettingsIMSURL();
		return this.ims_url;
	};

	public static readonly switchMode: () => void = () => {
		this.use_backup = !this.use_backup;
	};

	public static readonly LOGIN: string = "/login";
	public static readonly LOGOUT: string = "/logout";
	public static readonly ESRILOGIN: string = "/esrilogin";
	public static readonly GET = {
		MISSION_DATA: {
			AREA: "/get/incident/area",
			AREA_BY_ID: "/get/areabyid",
			AGENT: "/get/incident/resource",
			POI: "/get/incident/poi",
			POI_BY_ID: "/get/poibyid",
			HISTORY_CHANGES: "/get/mission/history_changes",
			CHANGES_TIMESTAMPS: "/get/incident/changes_timestamps",
			BUFFER_DATA: "/get/mission/bufferdata",
			ATTACHMENTS_ZIP: "/get/attachments/zip"
		},
		COMMAND_STRUCTURE: {
			COMMANDER: "/get/commander",
			SECTOR: "/get/sector",
			SECTOR_ID: "/get/sectorById",
			SUPPORT: "/get/support",
			SUPPORT_ID: "/get/supportById",
			SECTOR_ASSIGNATION: "/get/sectorassignation"
		},
		EVENT: "/get/event",
		AREA: "/get/area",
		AGENT: "/get/agent",
		HARDWARE: "/get/hardware",
		AGENT_LOG: "/get/resourcelog",
		DECISION: "/get/decision",
		DRONE: "/get/drone",
		CAMERA: "/get/camera",
		ATTACHMENTS: "/get/attachments",
		MISSION: "/get/incident",
		POI: "/get/poi",
		CONFIGURATION: "/get/configuration/v2",
		VIDEO_ALERT: "/get/videoalert",
		TELEMETRY: "/get/telemetry",
		MISSION_TELEMETRY: "/get/missiontelemetry",
		FILENAME: "/getfilename",
		FILENAMES: "/getfilenames",
		STATES: "/get/state",
		OVERLAYS: "/get/planes",
		OVERLAY: "/get/plane",
		FLOORS: "/get/floors",
		GPS_DEVICE: "/get/gpsdevice",
		TYPE: {
			AREA: "/get/areatype",
			AGENT: "/get/agenttype",
			POI: "/get/poitype",
			EVENT: "/get/eventtype",
			CAMERA: "/get/cameratype"
		},
		USER: "/get/user",
		USERSSO: "/getusersso",
		LEVEL: "/get/level",
		LAST_CHANGE: "/lastchange",
		SYNC_CHANGE: "/syncchanges",
		FILE: "/getfile",
		FILE_BY_ID: "/getfilebyid",
		BOUND_KML_NAME: "/getboundkmlname",
		KML: "/get/kml",
		TACTICS: "/get/tactics",
		ARA: "/get/aras",
		ARA_FULL: "/get/aras/v2",
		ARA_SCENARIO_REL: "/get/arascenariorel",
		ARA_RISKINFO_REL: "/get/arariskinforel",
		ARA_CM_REL: "/get/aracmrel",
		ARA_SCM_REL: "/get/arascmrel",
		HAZARD: "/get/hazards",
		RISKTYPE: "/get/risktypes",
		RISKRELATION: "/get/risks",
		SKILL: "/get/skill",
		SKILLUSERRELATION: "/get/skilluserrelation",
		APPLIANCE: "/get/appliance",
		CLOSED_APPLIANCE_RELATION: "/get/closedagentappliancerelation",
		APPLIANCE_RELATION: "/get/agentappliancerelation",
		APPLIANCE_RELATION_EXTENDED: "/get/agentappliancerelationextended",
		STATION: "/get/station",
		DECISIONFILE: "/get/decisionfile",
		SCENARIO: "/get/scenario",
		RISKINFORMATION: "/get/riskinformation",
		CONTROLMEASURETYPE: "/get/controlmeasuretype",
		SUBCONTROLMEASURETYPE: "/get/subcontrolmeasuretype",
		FSG: {
			SIMPLE: "/get/fsgsimple",
			FLAT: "/get/flat",
			ALL: "/get/fsg",
			HISTORY_CHANGES: "/get/fsghistory"
		},
		JESIP: {
			METHANE: "/get/jesipmethane",
			IIMARCH: "/get/jesipiimarch",
			JDM: "/get/jesipjdm",
			DEBRIEF: "/get/jesipdebrief",
			CHECKLIST: "/get/checklist",
			INCIDENT_INFORMATION: "/get/jesipincidentinformation",
			COMMANDER: "/get/jesipcommander"
		},
		AI_RECOMMENDATION: "/get/airecommendation",
		FILE_TOKEN: {
			GET: "/get/filetoken/",
			USE: "/get/filebytoken/"
		},
		INCIDENT_STREAMS: "/get/incidentstreams",
		INCIDENT_DOCUMENT: {
			DECISION: "/get/document/decision/"
		}
	};

	public static readonly SAVE = {
		MISSION_DATA: {
			AREA: "/save/mission/area"
		},
		FSG: {
			NEW: "/save/fsg",
			FLAT: "/save/flat",
			SIMPLE: "/save/fsgsimple"
		},
		COMMAND_STRUCTURE: {
			COMMANDER: "/save/structure/commander",
			SECTOR: "/save/structure/sector",
			SUPPORT: "/save/structure/support",
			SECTOR_ASSIGNATION: "/save/structure/sectorassignation"
		},
		EVENT: "/save/event",
		AREA: "/save/area",
		MISSION: "/save/incident",
		OVERLAY: "/save/plane",
		HARDWARE: "/save/hardware",
		FLOOR: "/save/floor",
		TACTICS: "/save/tactics",
		STATE: "/save/state",
		POI: "/save/poi",
		CONFIGURATION: "/save/configuration/v2",
		AGENT: "/save/agent",
		AGENT_ENTRY: "/save/agententry",
		DECISION: "/save/decision",
		DRONE: "/save/drone",
		RTMP_CAMERA: "/save/rtmpcamera",
		GPS_DEVICE: "/save/gps_device",
		TYPE: {
			AREA: "/save/areatype",
			AGENT: "/save/agenttype",
			POI: "/save/poitype",
			EVENT: "/save/eventtype"
		},
		KML: "/save/kml",
		USER: "/save/user",
		LEVEL: "/save/level",
		HAZARD: "/save/hazard",
		RISKTYPE: "/save/risktype",
		ARA: "/save/ara",
		FULL_ARA: "/save/fullara",
		ARA_SCENARIO_REL: "/save/arascenariorel",
		ARA_RISKINFO_REL: "/save/arariskinforel",
		ARA_CM_REL: "/save/aracmrel",
		ARA_SCM_REL: "/save/arascmrel",
		MEASURE: "/save/controlmeasure",
		RISKRELATION: "/save/risk",
		SKILLUSERRELATION: "/save/skilluserrelation",
		APPLIANCE: "/save/appliance",
		APPLIANCE_RELATION: "/save/agentappliancerelation",
		DECISIONFILE: "/save/decisionfile",
		SCENARIO: "/save/scenario",
		RISKINFORMATION: "/save/riskinformation",
		CONTROLMEASURETYPE: "/save/controlmeasuretype",
		SUBCONTROLMEASURETYPE: "/save/subcontrolmeasuretype",
		JESIP: {
			METHANE: "/save/jesipmethane",
			IIMARCH: "/save/jesipiimarch",
			JDM: "/save/jesipjdm",
			CHECKLIST: "/save/checklist",
			NEWCHECKLIST: "/add/checklist",
			DEBRIEF: "/save/jesipdebrief",
			INCIDENT_INFORMATION: "/save/jesipincidentinformation",
			COMMANDER: "/save/jesipcommander"
		}
	};

	public static readonly DELETE = {
		FSG: "/delete/fsg",
		FLAT: "/delete/flat",

		MISSION_DATA: {
			AREA: "/delete/mission/area",
			AREA_FOREVER: "/delete/mission/area_forever",
			POI: "/delete/mission/poi",
			AGENT: "/delete/mission/agent",
			DRONE: "/delete/mission/drone"
		},
		CAMERA: "/delete/camera",
		TYPE: {
			AGENT: "/delete/type/agent",
			AREA: "/delete/type/area",
			POI: "/delete/type/poi",
			EVENT: "/delete/type/event"
		},
		STRUCTURE: {
			SUPPORT: "/delete/support",
			SECTOR: "/delete/sector",
			SECTOR_ASSIGNATION: "/delete/sectorassignation"
		},
		KML: "/delete/kml",
		STATE: "/delete/state",
		MISSION: "/delete/mission",
		EVENT: "/delete/incident",
		HARDWARE: "/delete/hardware",
		PLANE: "/delete/plane",
		FLOOR: "/delete/floor",
		CONFIGURATION: "/delete/configuration", //TODO: Sure ¿? maybe only edit?
		GPS_DEVICE: "/delete/gps_device",
		USER: "/delete/user",
		HAZARD: "/delete/hazard",
		RISKTYPE: "/delete/risktype",
		SKILLUSERRELATION: "/delete/skilluserrelation",
		APPLIANCE: "/delete/appliance",
		APPLIANCE_RELATION: "/delete/agentappliancerelation",
		CONTROLMEASURETYPE: "/delete/controlmeasuretype",
		SUBCONTROLMEASURETYPE: "/delete/subcontrolmeasuretype",
		RISKINFORMATION: "/delete/riskinformation"
	};

	public static readonly REMOVE = {
		MISSION_DATA: {
			AREA: "/remove/mission/area",
			AGENT: "/remove/mission/agent",
			DRONE: "/remove/mission/drone",
			POI: "/remove/mission/poi"
		}
	};

	public static readonly ADD = {
		MISSION_DATA: {
			AGENT: "/add/mission/agent",
			DRONE: "/add/mission/drone"
		},
		FSG: "/add/fsgtomission",
		DEBRIEF: "/add/jesipdebrief"
	};

	public static readonly NEW = {
		EVENT_MISSION: "/new/eventmission"
	};
	public static readonly UPDATE = {
		MILESTONE_CAMERAS: "/updatemilestonecameras"
	};
	public static readonly LOG = {
		INFORMATION: "/log/info",
		WARNING: "/log/warn",
		ERROR: "/log/error"
	};

	public static readonly BEGIN_REQUEST: string = "/beginrequest";
	public static readonly ATTACH_TO: string = "/attachto";
	public static readonly DEATTACH: string = "/deattach";
	public static readonly UPLOAD: string = "/uploadfile";
	public static readonly UPLOADAUDIO: string = "/uploadaudio";
	public static readonly GRANT: string = "/grant";
	public static readonly DENY: string = "/deny";
	public static readonly TWITTER_EMBED: string = "https://publish.twitter.com/oembed";
	public static readonly INSTAGRAM_EMBED: string = "https://api.instagram.com/oembed";
	public static readonly AUTH: string = "/auth";
}

export interface Uri {
	host: string;
	port: number;
}
